import { Typography, TypographyProps } from '@mui/material';

export const H1 = (props: TypographyProps) => <Typography variant='h1' {...props} />;
export const H2 = (props: TypographyProps) => <Typography variant='h2' {...props} />;
export const H3 = (props: TypographyProps) => <Typography variant='h3' {...props} />;
export const H4 = (props: TypographyProps) => <Typography variant='h4' {...props} />;
export const H5 = (props: TypographyProps) => <Typography variant='h5' {...props} />;
export const H6 = (props: TypographyProps) => <Typography variant='h6' {...props} />;
export const Subtitle1 = (props: TypographyProps) => (
  <Typography variant='subtitle1' {...props} />
);
export const Subtitle2 = (props: TypographyProps) => (
  <Typography variant='subtitle2' {...props} />
);
export const Body1 = (props: TypographyProps) => (
  <Typography variant='body1' {...props} />
);
export const Body2 = (props: TypographyProps) => (
  <Typography variant='body2' {...props} />
);
export const Caption = (props: TypographyProps) => (
  <Typography variant='caption' {...props} />
);
export const Overline = (props: TypographyProps) => (
  <Typography variant='overline' {...props} />
);
